import './App.css';
import Header from './components/Header'
import AnimatedRoutes from './components/AnimatedRoutes';
import SocialIcons from './components/SocialIcons';

function App() {
  return (
    <div className="App">
      <Header />
      <AnimatedRoutes />
      <SocialIcons />
    </div>
  );
}

export default App;
